/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');
require('select2');

import 'datatables.net-bs4';

require('./menubar.js');
require('./mobile-menu.js');

require('./searchbar.js');

// To use Html5QrcodeScanner (more info below)
import {Html5QrcodeScanner} from "html5-qrcode";

//import {Html5Qrcode} from "html5-qrcode";

// To use Html5Qrcode (more info below)
//import {Html5Qrcode} from "html5-qrcode";
//https://scanapp.org/html5-qrcode-docs/docs/intro

document.addEventListener('DOMContentLoaded', function () {


    let modal = $('#scan-barcode');

    let html5QrcodeScanner = new Html5QrcodeScanner(
        "reader",
        {
            fps: 10,
            qrbox: 250,
            rememberLastUsedCamera: true,
        },true);


    let cancelScanningButton =  document.getElementById('cancel_scanning_button');
    cancelScanningButton.addEventListener('click', function () {
        $("#product").hide();
        $("#scanner").show();
        modal.modal('hide');
    });

    let submitAndNextButton = document.getElementById('submit-and-next');
    submitAndNextButton.addEventListener('click', function () {

        var form = $("#product_addtocart_form");
        var url = form.attr('action');

        $.ajax({
            type: "POST",
            url: url,
            data: form.serialize(), // serializes the form's elements.
            success:function(data)
            {
                //alert(data); // show response from the php script.
                $("#product").hide();
                html5QrcodeScanner.render(onScanSuccess);
                $("#scanner").show();
            },
            error:function(data) {
                $("#product").hide();
                html5QrcodeScanner.render(onScanSuccess);
                $("#scanner").show();
            }
        });
    });


    // Selecteer de start button
    let startButton = document.getElementById('start-scanner');

    // Voeg een click event listener toe aan de button
    startButton.addEventListener('click', function () {
        if (navigator.mediaDevices && typeof navigator.mediaDevices.getUserMedia === 'function') {
            $("#product").hide();
            html5QrcodeScanner.render(onScanSuccess);
            $("#scanner").show();
            modal.modal('show');
        } else {
            alert("Deze functionaliteit werkt alleen op een mobiele telefoon");
        }
    });

    function onScanFailure(error) {
        // handle scan failure, usually better to ignore and keep scanning.
        // for example:
        console.warn(`Code scan error = ${error}`);
       //html5QrcodeScanner.clear();
        html5QrcodeScanner.html5Qrcode.stop();
        alert("Deze code is niet bekend");
    }

    function validURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)?'+ // protocol
            '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+ // domain name
            '((\\d{1,3}\\.){3}\\d{1,3}))'+ // OR ip (v4) address
            '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+ // port and path
            '(\\?[;&a-z\\d%_.~+=-]*)?'+ // query string
            '(\\#[-a-z\\d_]*)?$','i'); // fragment locator
        return !!pattern.test(str);
    }


    function extractGTIN(url) {
        const regexQuery = /sku=([^&]+)/; // Matches artikelcode=XXXXXXX in query strings
        const regexGS1 = /\/240\/([^\/]+)/; // Matches the value after 240

        let matchQuery = url.match(regexQuery);
        let matchGS1 = url.match(regexGS1);

        if (matchQuery) {
            return matchQuery[1];
        } else if (matchGS1) {
            return matchGS1[1];
        }

        return null; // Return null if no GTIN is found
    }

    function onScanSuccess(decodedText, decodedResult) {
        let search = decodedText;
        if (validURL(search)){
            search = extractGTIN(search);
        }
        html5QrcodeScanner.html5Qrcode.stop();
        //haal het product op
        $.ajax({
            type: 'GET',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            url: "/singlesearch/" + search,
            success: function (data) {
                if (null !== data) {
                    let product_id = $("#product_id");
                    product_id.val(data.id);

                    let product_code = $("#product_code");
                    product_code.text("Artikelnummer: " + data.code);

                    let product_title = $("#product_title");
                    product_title.text(data.title);

                    $("#product_image").attr("src", data.productimage);

                    let amount_product = $("#amount_product");
                    amount_product.val('1');

                    let product_stock = $("#product_stock");
                    if (data.in_stock > 0) {
                        product_stock.html("<span class=\"dot available\"></span> op voorraad");
                    } else {
                        product_stock.html("<span class=\"dot delivery-time\"></span> Levertijd: 2 tot 4 werkdagen");
                    }
                    product_stock.val(data.in_stock);
                    $("#scanner").hide();
                    $("#product").show();
                } else {
                    html5QrcodeScanner.render(onScanSuccess);
                    alert("Deze code is onbekend");
                }
            },
            error: function (data) {
                html5QrcodeScanner.html5Qrcode.stop();
                alert(error);
            }
        });
    }

});
