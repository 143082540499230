$(function () {
    var $mobileMenu = $('.mobile-menu');
    $('.mobile-menu-button').click(function () {
        $mobileMenu.addClass('active');
    });

    $('.mobile-menu-close-button').click(function () {
        $mobileMenu.removeClass('active');
    });
});
