let lastsearch = "";
let available_results = [];

$('#searchbar').select2({
    dropdownCssClass : 'big-search-results',
    language: {
        errorLoading: function () {
          return "Er ging iets fout";
        },
        inputTooShort: function (args) {
            let remainingChars = args.minimum - args.input.length;

            let message = 'Typ ' + remainingChars + ' extra letters';

            return message;
        },
    },
    dropdownAutoWidth: true,
    tags: false,
    placeholder: 'Zoeken',
    minimumInputLength: 3,
    width: '100%',
    templateResult: formatProduct,
    ajax: {
        url: window.location.origin + '/livesearch/products',
        type: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        data: function (params) {
            var queryParameters = {
                search: params.term,
                type: 'searchbar',
            }
            lastsearch = params.term;
            return queryParameters;
        },
        processResults: function (data) {
            available_results = data;
            return {
                results: $.map(data, function (item) {
                    return {
                        text: item.title,
                        id: item.id,
                        filename: item.filename,
                        url: item.url
                    }
                })
            };
        },
    },
});

$('#searchbar').on('select2:select', function (e) {
    if ($('#searchbar').select2().val() == 0) {
        window.location.href = "/search/" + lastsearch;
    } else {
        $.ajax({
            type: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            dataType: 'json',
            url: window.location.origin + '/livesearch/products',
            data: {
                search: e.target.children[0].value,
                type: 'productexists',
            },
            success: function (data) {
                if (data && available_results) {
                    let search = e.target.children[0].text;
                    const asArray = Object.entries(available_results);
                    let foundItems = asArray.filter((item) => item[1].title === search);
                    let redirectUrl = window.location.origin + foundItems[0][1].url;
                    console.log(redirectUrl);
                    window.location.href = redirectUrl;
                }
            },
            error: function (data) {
                //
            }
        });
    }
});

function formatProduct (state) {
    if (!state.disabled) {
        if (state.filename == "" && state.id != -1 && state.id != -2) {
            return state.text;
        } else if (state.id == -1) {
            return "Geen resultaten";
        }

        var baseUrl = window.location.origin;
        var $state = $(
            '<span><img width="150" height="150" src="' + baseUrl + state.filename + '" class="img-flag" /> <br /><strong>' + state.text + '</strong></span>'
        );
        return $state;
    }
}

$('#customerid, #customer-select-id').on('change', function (e){
    let customerid = $(this).val();
    $.ajax({
        type : 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        dataType: 'json',
        url: window.location.origin + '/winkelwagen/klant',
        data:{
            customerid:  customerid,
        },
        success:function(data){

            $.ajax({
                type : 'POST',
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                },
                dataType: 'json',
                url : `/auth/impersonate`,
                data:{
                    customer_id: customerid,
                },
                success: function(response) {
                    if (response.success) {

                    } else {
                        alert('Gebruiker niet gevonden: ' + response.message);
                    }
                },
                error: function() {
                    alert('An error occurred.');
                }
            });
            location.reload(true);
        },
        error:function(data) {
            //
        }
    });
});

$('#customer-search').on('keyup', function (e) {
    $("#customer-search-submit").prop("disabled", true);
    let str = $(this).val();
    if (str.length > 1) {
        $.ajax({
            url: "/admin/customer/search",
            type: "GET",
            data: {
                search: str
            },
            success: function (data) {
                $("#customer-select-id").prop("disabled", false);
                if (data.length === 0) {
                    $("#customer-search-result").html("Geen resultaten gevonden.");
                    $("#customer-select-id").html("<option selected disabled>Probeer opnieuw</option>");

                } else {
                    $("#customer-search-result").html('Er zijn ' + data.length + ' resultaten gevonden.');
                    $("#customer-search-submit").prop("disabled", false);
                    $("#customer-select-id").html("<option selected disabled>Kies een optie:</option>");
                }
                for (var i = 0; i < data.length; i++) {
                    $("#customer-select-id").append("<option class='customer-selection-item' value=" + data[i].id + ">" + data[i].name + "</option>");
                }
            }
        });
    } else {
        $("#customer-select-id").prop("disabled", true);
        // Clear result div
        $("#customer-search-result").html('Vul minstens 1 tekens in om te zoeken.');
    }
});
